<template>
  <div>
    <validation-observer
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>

        <b-row class="mb-2">
          <b-col>
            <b-avatar
            rounded
              size="70px"
              class="mr-2"
              :src="require('@/assets/images/avatar/groups/' + (group.id_avatar || 'null') + '.png?v4')"

            />
            <b-button
              :variant="!showAvatarOptions?'primary':'secondary'"
              @click="showAvatarOptions = !showAvatarOptions"
            >
              {{ showAvatarOptions ? $t('table.hide') : $t('table.change')}}
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="showAvatarOptions">
          <b-col>
            <avatar-modal-selector
                :avatar_id="group.id_avatar"
                @set-avatar="setAvatar"
                @toggle-edit-avatar="showAvatarOptions = false"
            ></avatar-modal-selector>
          </b-col>
        </b-row>


        <b-row>
          <!-- Full Name -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('fields.name')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="group.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>
         </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="button"
      @click="submitChanges"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="button"
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ name: 'school-groups-view', 
          params: { id: school.id,
            group_id: group.id, } })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import AvatarModalSelector from './AvatarModalSelector.vue'

export default {
  components:{
    AvatarModalSelector,
    
    BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
    ValidationProvider, ValidationObserver,
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
  props:{
    school: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      required,

      showAvatarOptions:false,

    }
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    setAvatar(id){
        this.group.id_avatar = id;
        //this.showAvatarOptions = false
    },
    async submitChanges(){
      console.log(this.group)
      let data = {
          'id': this.group.id,
          'name':this.group.name,
          'id_avatar':this.group.id_avatar,
          'tab':'main',
      }
      await this.$http.post('school/group/edit', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>